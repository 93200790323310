<template>
	<div>
		<div class="selectionBox">
			<div class="sb_forDiv" v-for="(item,index) in dataList1" :key="index" v-if="item.isdele==false" @click="selectBoxClick(index)">
				<!-- <el-image style="position: absolute;bottom:0px;right: 0;width: 14px;height: 14px;" v-if="dataIndex1==index" :src="require('@/assets/img/choose-active@ico.png')"></el-image> -->
				<div class="sb_fd_text" :style="dataIndex1==index?'border: 1px solid red;' : ''" v-if="$i18n.locale=='cn'">{{item.name}}</div>
				<div class="sb_fd_text" :style="dataIndex1==index?'border: 1px solid red;' : ''" v-if="$i18n.locale=='en'">{{item.enname}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
			dataIndex:{
				type:Number,
				default:null
			}
		},
		data() {
			return {
				dataIndex1:this.dataIndex,
				dataList1:this.dataList,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			dataIndex(val){
				this.dataIndex1=val;
			},
			dataList(val){
				// console.log("val",val)
				// this.dataList1 = val;
				// if(this.dataList1[this.dataIndex1].type==false){
				// 	var a = 0;
				// 	this.dataList1.forEach(res=>{
				// 		if(res.type==true){
				// 			this.dataIndex1 = a
				// 			return this.dataIndex1;
				// 		}else{
				// 			a = a+1
				// 		}
				// 	})
				// }
			}
		},
		
		computed:{
			
		},
		
		methods:{
			//--------------------单选/多选框选择------------------------------------
			selectBoxClick:function(index){
				this.dataIndex1=index;
				this.$emit('select',index)
			}
		}
	}
</script>

<style lang="less" scoped>
	.selectionBox{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		
		.sb_forDiv{
			position: relative;
			margin-right: 10px;
			cursor: pointer;
			
			.sb_fd_text{
				padding: 6px 12px;
				border: 1px solid #dfe1e6;
				background: #fff;
				color: #888;
				font-size: 10px;
				margin-bottom: 10px;
			}
		}
	}
	
	.sb_forDiv:hover .sb_fd_text{
		border: 1px solid red;
	}
</style>